@import '../modules/colors', '../modules/fonts', '../modules/shadows', '../modules/containers',
	'../modules/breakpoints';

.welcome {
	@include center-child();
	background-image: linear-gradient(to bottom right, $brandNavy 50%, $brandYellow 150%);
	// background-color: $brandNavy;
	height: 100vh;
	.smallLoader {
		color: $backgroundWhite;
	}
	&__panel {
		@include box-shadow();
		@include basic-flex(column);
		background-color: $backgroundGrey;
		padding: 15px 25px;
		border-radius: 15px;
		width: 340px;
		@media screen and (max-width: $maxPhone) {
			max-width: calc(90vw - 50px);
		}
		img {
			@include box-shadow();
			border-radius: 12px;
		}
		h3 {
			color: $brandNavy;
		}
		input {
			@include box-shadow-hover-transition();
			text-align: center;
			font-family: $baloo;
			color: $brandNavy;
			font-weight: 500;
			padding: 5px;
			margin-bottom: 15px;
			line-height: 1.5rem;
			border-radius: 5px;
			width: 80%;
		}
		&__error {
			margin-top: 0;
			height: 1.6rem;
			font-weight: 600;
			color: $error;
			transition: 0.3s;
			@media screen and (max-width: $maxPhone) {
				font-size: 0.6rem;
			}
		}
	}
}

.loginWithGoogleButton {
	&__gIcon {
		background-size: cover;
		height: 20px;
		width: 20px;
		margin-right: 5px;
	}
	@include basic-flex();
	background: none;
	color: #fff;
	font-weight: 600;
	font-family: $baloo;
	&:hover {
		cursor: pointer;
		text-shadow: 0 3px 3px rgba($color: #000000, $alpha: 0.4);
	}
}
