@import '../modules/colors', '../modules/fonts', '../modules/shadows', '../modules/containers';

.login {
	@include center-child();
	background: $backgroundGrey;
	height: 100vh;
	&__box {
		@include box-shadow();
		@include basic-flex(column);
		border: solid 5px $brandYellow;
		background: $brandNavy;
		color: $backgroundWhite;
		text-align: center;
		padding: 20px;
		width: 40%;
		border-radius: 15px;
		@media screen and (max-width: $maxTablet) {
			width: 60%;
		}
		@media screen and (max-width: $maxPhone) {
			width: 80%;
		}
		h1 {
			margin-bottom: 0;
			@include yellow-box-shadow(4px);
		}
		&__inputs {
			width: 100%;
			input {
				width: 60%;
				@media screen and (max-width: $maxPhone) {
					width: 80%;
				}
				margin-top: 40px;
				text-align: center;
				font-size: 16px;
				border-radius: 15px;
				padding: 10px;
				border: solid 3px $backgroundWhite;
				&:active,
				&:focus {
					border: solid 3px $brandYellow;
				}
			}
			.error {
				border: solid 3px $error;
			}
		}
		&__error {
			height: 5vh;
			font-weight: 600;
			color: $error;
			margin-bottom: 10px;
		}
	}
}

.onboarding {
	@extend .login;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	h1 {
		color: #fff;
	}
	&__stepTracker {
		display: flex;
		justify-content: space-evenly;
		align-items: center;
		width: 50%;
		max-width: 400px;
		@media screen and (max-width: $maxTablet) {
			width: 60%;
		}
		@media screen and (max-width: $maxPhone) {
			width: 80%;
		}
		.step {
			&__circle {
				background: inherit;
				height: 10px;
				width: 10px;
				border-radius: 100%;
				border: solid #fff 1px;
			}
			&__pipe {
				flex-grow: 1;
				background: $darkGrey;
				height: 5px;
				&--visited {
					@extend .step__pipe;
					background: $brandNavy;
				}
			}
			&--current {
				.step__circle {
					background: $brandYellow;
					border: solid $brandYellow 1px;
				}
			}
			&--visited {
				.step__circle {
					background: $backgroundWhite;
					border: solid #fff 1px;
				}
			}
		}
		margin-bottom: 50px;
	}
	&__panels {
		display: flex;
		justify-content: center;
		width: 60%;
		align-items: center;
		@media screen and (max-width: $maxTablet) {
			flex-direction: column;
			align-items: center;
		}
		&__panel {
			background: $backgroundWhite;
			padding: 20px 15px;
			color: $brandNavy;
			border-radius: 15px;
			border: solid #fff 3px;
			@include box-shadow();
			text-align: center;
			width: 100px;
			margin-bottom: 10px;
			margin: 0 15px;
			h2 {
				margin: 0;
			}
			@media screen and (max-width: $maxTablet) {
				margin-top: 20px;
			}
			transition: 0.3s;
			&:hover,
			&:active,
			&:focus {
				outline: none;
				cursor: pointer;
				border-color: $brandYellow;
				.accountTypeIcon {
					background: $brandYellow;
				}
			}
			&--selected {
				@extend .onboarding__panels__panel;
				border-color: $brandYellow;
				text-decoration: underline;
			}
			.accountTypeIcon {
				height: 50px;
				width: 50px;
				color: $brandNavy;
				background: $brandNavy;
				margin: auto;
				transition: 0.3s;
				// &--brewery {
				// 	@extend .accountTypeIcon;
				// 	mask: url('../images/breweryIcon.svg') no-repeat center;
				// }
				// &--bar {
				// 	@extend .accountTypeIcon;
				// 	mask: url('../images/barIcon.svg') no-repeat center;
				// }
			}
		}
	}
	&__form {
		@include box-shadow();
		background: $backgroundWhite;
		border: solid 4px $brandNavy;
		border-radius: 15px;
		padding: 5px 25px 25px;
		max-width: 500px;
		&--columns {
			@extend .onboarding__form;
			display: flex;
			justify-content: space-between;
			@media screen and (max-width: $maxTablet) {
				flex-direction: column;
			}
		}
		&__column {
			padding: 0 10px;
		}
		&__row {
			h3 {
				color: $brandNavy;
			}
			input {
				@include body-text();
				@include box-shadow();
				height: 2rem;
				padding: 5px 15px;
				background: $shadowGrey;
				border-radius: 15px;
				width: 90%;
			}
			.fileSelectButton {
				@include box-shadow();
				margin-top: 20px;
				display: block;
				background: $brandNavy;
				@include header-text(1rem);
				color: $backgroundWhite;
				text-align: center;
				padding: 5px;
				border-radius: 15px;
				transition: 0.3s;
				&:hover {
					cursor: pointer;
					color: $brandYellow;
				}
			}
		}
	}
	&__nextButton {
		@include basic-flex();
		@include box-shadow();
		border-radius: 5px;
		color: $brandNavy;
		font-weight: 800;
		font-size: 2rem;
		padding: 0px 15px;
		background: $backgroundWhite;
		font-family: $baloo;

		margin-top: 40px;
		svg {
			margin-right: 5px;
		}
		&:hover,
		&:focus,
		&:active {
			margin-bottom: 5px;
			margin-top: 35px;
			box-shadow: 0 3px 6px rgba($color: #000000, $alpha: 0.18), 0 5px $brandYellow;
		}
		&--disabled {
			@extend .onboarding__nextButton;
			background: $shadowGrey;
			color: $darkGrey;
			&:hover,
			&:focus,
			&:active {
				cursor: not-allowed;
				margin-bottom: 0px;
				margin-top: 40px;
				box-shadow: 0 3px 6px rgba($color: #000000, $alpha: 0.18);
			}
		}
	}
}

.welcome {
	@include center-child();
	background-image: linear-gradient(to bottom right, $brandNavy 50%, $brandYellow 150%);
	// background-color: $brandNavy;
	height: 100vh;
	h1 {
		color: #fff;
		font-size: 1.5rem;
		text-align: center;
	}
	.smallLoader {
		color: $backgroundWhite;
	}
	&__panel {
		@include box-shadow();
		@include basic-flex(column);
		background-color: $backgroundGrey;
		padding: 15px 25px;
		border-radius: 15px;
		width: 340px;
		@media screen and (max-width: $maxPhone) {
			max-width: calc(90vw - 50px);
		}
		img {
			@include box-shadow();
			border-radius: 12px;
		}
		h3 {
			color: $brandNavy;
		}
		h4 {
			text-align: left;
			margin: 5px 0;
			color: $brandNavy;
		}
		select,
		input {
			@include box-shadow-hover-transition();
			text-align: center;
			font-family: $baloo;
			color: $brandNavy;
			font-weight: 500;
			padding: 5px;
			margin-bottom: 15px;
			line-height: 1.5rem;
			border-radius: 5px;
			width: 80%;
			border: none;
			outline: $brandNavy;
		}
		&__error {
			margin-top: 0;
			height: 1.6rem;
			font-weight: 600;
			color: $error;
			transition: 0.3s;
			@media screen and (max-width: $maxPhone) {
				font-size: 0.6rem;
			}
		}
		&__row {
			@include basic-flex();
			justify-content: space-between;
			width: 80%;
		}
	}
	&__verifyEmail {
		@extend .welcome;
		flex-direction: column;
		justify-content: center;
		height: 80vh;
		color: #fff;
		.sendEmailButton {
			margin-top: 20px;
			@include box-shadow();
			@include header-text(1rem);
			background: $brandNavy;
			border-radius: 15px;
			color: $backgroundWhite;
			padding: 10px;
			transition: 0.3s;
			&:hover {
				color: $brandYellow;
			}
		}
		.signOutButton {
			margin-top: 20px;
			@include box-shadow();
			@include body-text();
			background: $backgroundGrey;
			border-radius: 10px;
			color: $darkGrey;
			padding: 5px;
			transition: 0.3s;
			&:hover {
				color: $brandNavy;
			}
		}
	}
}
