@import '../modules/colors', '../modules/fonts', '../modules/shadows', '../modules/containers',
	'../modules/breakpoints';

.brewery {
	@include basic-flex(column);
	padding: 50px 150px;
	@media screen and (max-width: $maxTablet) {
		padding: 50px 50px;
	}
	@media screen and (max-width: $maxPhone) {
		padding: 40px 10px;
	}
	&__info {
		@include basic-flex();
		width: 100%;
		margin-bottom: 50px;
		&__image {
			@include profile-image(200px);
			margin: 0;
			margin-right: 25px;
			@media screen and (max-width: $maxTablet) {
				@include profile-image(150px);
				margin: 0;
				margin-right: 25px;
			}
			@media screen and (max-width: $maxPhone) {
				@include profile-image(100px);
				margin: 0;
				margin-right: 25px;
			}
		}
		&__text {
			@include basic-flex(column);
			height: 200px;
			@media screen and (max-width: $maxTablet) {
				height: 150px;
			}
			@media screen and (max-width: $maxPhone) {
				height: 100px;
			}
			align-items: flex-start;
			h1 {
				margin-top: 0;
				color: $brandNavy;
				@media screen and (max-width: $maxPhone) {
					font-size: 1.5rem;
				}
			}
			span {
				color: $darkGrey;
				font-weight: 500;
			}
		}
	}
	&__products {
		@include basic-flex(column);
		align-items: flex-start;
		width: 100%;
		margin-bottom: 50px;
		h3 {
			color: $brandNavy;
		}
		&__productList {
			@include basic-flex(column);
			align-items: flex-start;
			width: 80%;
			@media screen and (max-width: $maxPhone) {
				width: 100%;
			}
			.explore__searchResults__product {
				width: 100%;
			}
		}
	}
	.blueButton {
		align-self: flex-start;
	}
}
