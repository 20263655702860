@mixin basic-flex($direction: row) {
	display: flex;
	align-items: center;
	flex-direction: $direction;
}

@mixin center-child() {
	display: flex !important;
	align-items: center;
	justify-content: center;
}

@mixin profile-image-overlay($noPhoto: false) {
	@include center-child();
	border-radius: 10px;
	width: 100%;
	height: 100%;
	@if $noPhoto {
		background-color: rgba(0, 0, 0, 0.1);
		color: $darkGrey;
		opacity: 1;
		transition: 0.3s;
		&:hover {
			background-color: rgba(0, 0, 0, 0.3);
			color: $brandNavy;
			cursor: pointer;
		}
	} @else {
		background-color: rgba(0, 0, 0, 0.3);
		color: $backgroundWhite;
		opacity: 0;
		transition: 0.3s;
		&:hover {
			opacity: 1;
			cursor: pointer;
		}
	}
}

@mixin profile-image($size: 150px) {
	@include box-shadow-hover-transition();
	background-color: $backgroundWhite;
	background-size: cover;
	background-position: center;
	min-width: $size;
	min-height: $size;
	max-width: $size;
	max-height: $size;
	height: $size;
	margin: 25px;
	border-radius: 0.5rem;
	&:hover {
		cursor: pointer;
	}
}

@mixin basic-image($size: 100px) {
	@include box-shadow();
	background-color: $backgroundWhite;
	background-size: cover;
	background-position: center;
	min-width: $size;
	min-height: $size;
	max-width: $size;
	max-height: $size;
	height: $size;
	border-radius: 10px;
}

@mixin modal-inner($width: 50%, $maxWidth: 800px, $height: max-content) {
	@include box-shadow();
	@include basic-flex(column);
	align-items: center;
	justify-content: space-between;
	width: $width;
	max-width: $maxWidth;
	height: $height;
	padding: 10px;
	outline: none;
	background: white;
	border-radius: 15px;
	overflow: scroll;
	z-index: 10;
	@media screen and (max-width: $maxTablet) {
		width: 70%;
	}
	@media screen and (max-width: $maxPhone) {
		width: 90%;
	}
	input {
		@include body-text();
		@include box-shadow();
		height: 2rem;
		padding: 5px 15px;
		background: $shadowGrey;
		border-radius: 15px;
		width: 80%;
		max-width: 300px;
	}
}

.profileImage {
	@include profile-image();
	&__overlay {
		@include profile-image-overlay();
		&--noPhoto {
			@include profile-image-overlay($noPhoto: true);
		}
	}
	&--noPhoto {
		@extend .profileImage;
		background-color: $backgroundGrey;
	}
	&--small {
		@include profile-image();
		min-width: 50px;
		min-height: 50px;
		width: 50px;
		height: 50px;
		border-radius: 5px;
		margin: 0;
	}
	&--icon {
		@include profile-image();
		min-width: 18px;
		min-height: 18px;
		width: 18px;
		height: 18px;
		border-radius: 3px;
		margin: 0;
		margin-right: 4px;
	}
}

.image {
	@include box-shadow-hover-transition();
	background-color: #fff;
	background-size: cover;
	background-position: center;
	border-radius: 0.5rem;
	&:hover {
		cursor: pointer;
	}
	&__overlay {
		@include profile-image-overlay();
		&--noPhoto {
			@include profile-image-overlay($noPhoto: true);
		}
	}
	&--noPhoto {
		@extend .image;
		background-color: $bg-grey;
	}
}

.basicFlex {
	@include basic-flex();
	&--column {
		@include basic-flex(column);
	}
}

.centeredPage {
	@include center-child();
	flex-direction: column;
}

.center-child {
	@include center-child();
	flex-direction: column;
	width: 100%;
	height: 100%;
}

.flex {
	@include basic-flex();
}

.flex-col {
	@include basic-flex();
	flex-direction: column;
}
