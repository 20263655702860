@import '../modules/colors';

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	30% {
		transform: rotate(159deg);
	}
	50% {
		transform: rotate(179deg);
	}
	80% {
		transform: rotate(339deg);
	}
	100% {
		transform: rotate(359deg);
	}
}

.fullscreenLoader {
	height: 90vh;
	width: 100%;
	@include center-child();
	flex-direction: column;
	color: $brandNavy;
	&__spinner {
		font-size: 5rem !important;
		animation: spin 1.5s infinite linear;
	}
	&__text {
		font-size: 2rem;
	}
}

.smallLoader {
	height: 20vh;
	width: 20vh;
	@include center-child();
	flex-direction: column;
	color: $brandNavy;
	&__spinner {
		font-size: 5rem !important;
		animation: spin 1.5s infinite linear;
	}
	&__text {
		font-size: 2rem;
	}
}
