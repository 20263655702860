// Brand Main Colors
$brandNavy: #053574;
$brandYellow: #ffbd59;
$brand-navy: #053574;
$brand-yellow: #ffbd59;
// Shadows & Textures
$backgroundWhite: #ffffff;
$backgroundGrey: #f5f5f5;
$shadowGrey: #eaeaea;
$darkGrey: #999999;
$bg-grey: #f5f5f5;
$shadow-grey: #eaeaea;
$medium-grey: #cccccc;
$dark-grey: #999999;
// status
$error: rgb(219, 66, 66);
$success: rgb(19, 192, 91);
$green: $success;

@mixin brand-gradient-background() {
	background-image: linear-gradient(to bottom right, $brandNavy, $brandYellow);
}

.green {
	color: $green;
}

.red {
	color: $error;
}

.brand-navy {
	color: $brand-navy;
}

.brand-yellow {
	color: $brand-yellow;
}

.dark-grey {
	color: $dark-grey;
}
