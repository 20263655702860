@import '../modules/colors', '../modules/fonts', '../modules/shadows', '../modules/containers',
	'../modules/breakpoints';

.myCart {
	@include basic-flex(column);
	&--empty {
		@extend .myCart;
		color: $brandNavy;
		padding: 50px 5px;
	}
	&__link {
		@include box-shadow-hover-transition();
		background-color: $brandNavy;
		color: $backgroundWhite;
		padding: 5px;
		margin-bottom: 10px;
		border-radius: 10px;
	}
	&__overview {
		@include basic-flex();
		@include box-shadow();
		background-color: $backgroundGrey;
		padding: 20px 0;
		border-radius: 15px;
		align-items: flex-start;
		margin: 50px 0 25px;
		&__item {
			@include basic-flex(column);
			height: 60px;
			align-items: flex-start;
			justify-content: space-between;
			margin: 0 25px;
			h2 {
				color: $brandNavy;
				margin: 0;
			}
			span {
				color: $darkGrey;
				font-weight: 700;
			}
		}
	}
	h3 {
		margin: 25px 0 0;
	}
	span {
		color: $darkGrey;
		font-weight: 500;
	}
	&__orders {
		margin-bottom: 100px;
		&__order {
			@include basic-flex(column);
			@include box-shadow();
			background-color: $backgroundGrey;
			border-radius: 15px;
			padding: 10px 10px 0;
			margin: 20px 0;
			span {
				font-weight: 700;
				color: $brandNavy;
			}
			&__product {
				@include basic-flex();
				align-self: start;
				margin-bottom: 20px;
				&__image {
					@include profile-image(70px);
					margin: 0 5px;
				}
				span {
					font-weight: 500;
					margin-left: 10px;
				}
			}
		}
	}
}

.accountCart {
	@extend .myCart;
	flex-grow: 1;
	width: 100%;
	padding-top: 10px;
	&--empty {
		@extend .myCart;
		color: $brandNavy;
		padding: 50px 5px;
		a {
			@include box-shadow-hover-transition();
			background-color: $brandNavy;
			color: $backgroundWhite;
			padding: 5px;
			margin-bottom: 10px;
			border-radius: 10px;
		}
	}
	&__overview {
		@include basic-flex();
		@include box-shadow();
		background-color: $backgroundWhite;
		padding: 20px 0;
		border-radius: 15px;
		align-items: flex-start;
		margin: 10px 0 15px;
		width: 80%;
		&__item {
			@include basic-flex(column);
			height: 60px;
			align-items: flex-start;
			justify-content: space-between;
			margin: 0 25px;
			h2 {
				color: $brandNavy;
				margin: 0;
			}
			span {
				color: $darkGrey;
				font-weight: 700;
			}
		}
	}
	h3 {
		margin: 25px 0 0;
	}
	span {
		color: $darkGrey;
		font-weight: 600;
	}
	&__orders {
		width: 80%;
		&__order {
			@include basic-flex(column);
			@include box-shadow();
			background-color: $backgroundWhite;
			border-radius: 15px;
			padding: 10px 10px 0;
			margin: 20px 0;
			span {
				font-weight: 700;
				color: $brandNavy;
			}
			&__product {
				@include basic-flex();
				align-self: start;
				margin-bottom: 20px;
				&__image {
					@include profile-image(50px);
					margin: 0 5px;
				}
				span {
					font-weight: 500;
					margin-left: 10px;
				}
			}
		}
	}
}
