@import '../modules/colors', '../modules/fonts', '../modules/shadows', '../modules/containers',
	'../modules/breakpoints';

.receipt {
	padding: 50px 0;
	@include basic-flex(column);
}

.receipts {
	padding: 50px 0;
	@include basic-flex(column);
	align-items: center;
	h2 {
		margin: 0;
	}
	.receiptInList {
		@include basic-flex();
		font-weight: 800;
		padding: 10px 20px;
		margin-bottom: 10px;
		border-radius: 10px;
		width: 50%;
		max-width: 400px;
		span {
			flex-grow: 1;
		}
		&__downloadBtn {
			@include box-shadow-hover-transition();
			@include center-child();
			padding: 3px;
			margin-left: 10px;
			border-radius: 4px;
			color: $darkGrey;
			&:hover {
				cursor: pointer;
				color: $brandNavy;
			}
		}
	}
}

.accountReceipts {
	@extend .receipts;
	width: 100%;
	margin: 0;
	color: $darkGrey;
	@media screen and (max-width: $maxPhone) {
		font-size: 0.8rem;
	}
	.receiptInList {
		width: 90%;
		background-color: #fff;
		color: $brandNavy;
	}
}

.receiptDisplay {
	@include box-shadow();
	margin-bottom: 25px;
	p {
		margin: 0;
	}
	@media screen and (max-width: $maxPhone) {
		width: 90%;
	}
}
