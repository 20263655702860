@import './modules/fonts', './modules/colors', './modules/animations';
@import './components/buttons', './components/loaders', './components/snackbars',
	'./components/modals';
@import './pages/loginSignup', './pages/myProducts', './pages/myCart', './pages/explore',
	'./pages/orders', './pages/brewery', './pages/welcome', './pages/notifications', './pages/order',
	'./pages/receipts', './pages/map', './pages/analytics';

html,
body {
	padding: 0;
	margin: 0;
	font-family: $baloo, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: $backgroundWhite;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.content {
	min-height: 85vh;
}

a {
	text-decoration: none;
	color: inherit;
	transition: 0.3s;
	outline: none;
	&:hover {
		cursor: pointer;
	}
}

hr {
	background-color: $shadowGrey;
	height: 3px;
	border: none;
	border-radius: 3px;
	margin: 15px 0;
}

input {
	border: none;
	outline: none;
	transition: 0.3s;
}

.basicInput {
	@include box-shadow-hover-transition();
	background-color: $backgroundGrey;
	padding: 5px;
	border-radius: 5px;
	margin-bottom: 10px;
	font-weight: 500;
}

.select {
	padding: 0.5rem 1rem;
	font-family: $baloo;
	font-weight: 500;
	@include box-shadow-hover-transition();
}

input,
textarea {
	@include box-shadow-hover-transition();
	@include body-text();
	border: none;
	outline: none;
	transition: 0.3s;
	border-radius: 0.5rem;
	background-color: $bg-grey;
	padding: 5px 10px;
}

button {
	border: none;
	outline: none;
	transition: 0.3s;
	&:hover {
		cursor: pointer;
	}
}

.page {
	@extend .fade-in;
	min-height: 80vh;
}

.link {
	color: $brandNavy;
	text-decoration: underline;
	&:hover {
		color: $brandYellow;
		cursor: pointer;
	}
}

input {
	font-family: $baloo;
}

.error {
	color: $error;
}

* {
	box-sizing: border-box;
}

.order-status-bubble {
	border-radius: 100%;
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
	background-color: $dark-grey;
	width: 1rem;
	height: 1rem;
	display: inline-block;
}

.bg-grey {
	background-color: $bg-grey;
}
.bg-shadow-grey {
	background-color: $shadow-grey;
}
.bg-dark-grey {
	background-color: $dark-grey;
}
.bg-navy {
	background-color: $brand-navy;
}
.bg-yellow {
	background-color: $brand-yellow;
}
.bg-green {
	background-color: $success;
}
.bg-red {
	background-color: $error;
}
.bg-white {
	background-color: #fff;
}
