@import '../modules/colors', '../modules/fonts', '../modules/shadows', '../modules/containers',
	'../modules/breakpoints';

.breweries {
	padding: 0 50px 150px;
	h1 {
		color: $brandNavy;
	}
	p {
		margin-bottom: 50px;
		font-weight: 500;
		color: $darkGrey;
	}
	&__list {
		&__brewery {
			@include basic-flex();
			&__image {
				@include profile-image(80px);
				background-color: $backgroundGrey;
				border-radius: 10px;
				margin: 5px;
			}
			&__info {
				margin-left: 10px;
				h4 {
					margin: 0;
					color: $brandNavy;
					transition: 0.3s;
					&:hover {
						color: $brandYellow;
					}
				}
				span {
					color: $darkGrey;
					font-weight: 500;
				}
			}
		}
	}
}
