@import './colors.scss';

$baloo: 'Baloo 2', 'sans-serif';

@mixin header-text($size: 4rem, $color: $brandNavy) {
	font-family: $baloo;
	font-size: $size;
	font-weight: 800;
	color: $color;
}

@mixin body-text($size: 1rem, $color: #000000) {
	font-family: $baloo;
	font-size: $size;
	font-weight: 500;
	color: $color;
}

@mixin text-shadow() {
	text-shadow: $shadowGrey 0 2px 5px;
}
