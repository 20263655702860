@import '../modules/colors', '../modules/fonts', '../modules/shadows', '../modules/containers',
	'../modules/breakpoints';

@keyframes flashYellow {
	0% {
		color: $darkGrey;
	}
	50% {
		color: $brandYellow;
	}
	0% {
		color: $darkGrey;
	}
}

.myProducts {
	@include basic-flex(column);
	padding: 50px 0 150px;
	&__productList {
		@include box-shadow();
		border-radius: 15px;
		background-color: $backgroundGrey;
		width: 80%;
		max-width: 800px;
		padding: 25px;
		&__controls {
			@include basic-flex();
			justify-content: space-between;
			@media screen and (max-width: $maxPhone) {
				flex-direction: column;
			}
			&__button {
				font-weight: 700;
				color: $darkGrey;
				padding: 5px 10px;
				margin: 0 15px 0 0;
				@media screen and (max-width: $maxPhone) {
					margin: 0 5px;
				}
				border-radius: 10px;
				transition: 0.3s;
				&:hover {
					@include box-shadow();
					border-color: solid 2px $brandYellow;
					color: $brandYellow;
					background-color: $brandNavy;
					cursor: pointer;
				}
				&--selected {
					@extend .myProducts__productList__controls__button;
					@include box-shadow();
					background-color: $brandNavy;
					color: $backgroundWhite;
				}
			}
			&__help {
				@include center-child();
				color: $darkGrey;
				margin-right: 10px;
				transition: 0.3s;
				&:hover {
					color: $brandNavy;
					cursor: pointer;
				}
			}
			input {
				@include body-text();
				background-color: $shadowGrey;
				padding: 10px;
				border-radius: 15px;
				@media screen and (max-width: $maxPhone) {
					margin-top: 15px;
				}
			}
		}
		&__product {
			@include basic-flex();
			@include box-shadow-hover-transition();
			@media screen and (max-width: $maxPhone) {
				flex-direction: column;
			}
			flex-wrap: wrap;
			background-color: $backgroundWhite;
			border-radius: 15px;
			padding: 0 5px;
			margin: 10px 0;
			&__image {
				@include profile-image(100px);
				min-width: 100px;
			}
			&__info {
				@include basic-flex(column);
				align-items: flex-start;
				min-height: 100px;
				max-width: 50%;
				@media screen and (max-width: $maxPhone) {
					max-width: 90%;
				}
				color: $brandNavy;
				&__header {
					@include basic-flex();
					margin: 0;
					svg {
						color: $darkGrey;
						margin-left: 10px;
						height: 20px;
						width: 20px;
					}
				}
				.tag {
					color: $darkGrey;
					font-weight: 600;
				}
			}
			&__actionsContainer {
				@include basic-flex();
				margin-left: auto;
				@media screen and (max-width: $maxPhone) {
					margin-left: 0;
					margin-bottom: 10px;
				}
			}
			&__actions {
				@include basic-flex(column);
				margin: 0 5px;
				@media screen and (max-width: $maxPhone) {
					flex-direction: row;
					margin: 5px;
				}
				&__button {
					@include center-child();
					@include box-shadow-hover-transition();
					background-color: $backgroundGrey;
					color: $darkGrey;
					border-radius: 5px;
					padding: 10px;
					margin: 5px;
					&:hover {
						color: $brandNavy;
						cursor: pointer;
					}
					&--attention {
						@extend .myProducts__productList__product__actions__button;
						animation-name: flashYellow;
						animation-duration: 2s;
						animation-iteration-count: 10;
					}
					&--favAdd {
						@extend .myProducts__productList__product__actions__button;
						&:hover {
							color: $brandYellow;
						}
					}
					&--favRemove {
						@extend .myProducts__productList__product__actions__button;
						color: $brandYellow;
					}
				}
			}
			&__bottom {
				@include center-child();
				flex-direction: column;
				width: 100%;
				margin-bottom: 20px;
				span {
					font-weight: 600;
					color: $brandNavy;
					&:hover {
						cursor: pointer;
					}
				}
			}
		}
		&__loading {
			@include center-child();
		}
		&__noProducts {
			color: $brandNavy;
		}
	}
}
