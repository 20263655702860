@import '../modules/colors', '../modules/fonts', '../modules/shadows', '../modules/containers';

button {
	font-family: $baloo;
}

.button {
	@include basic-flex();
	max-width: max-content;
	border-radius: 5px;
	color: $brandNavy;
	padding: 5px 15px;
	font-weight: 800;
	font-size: 16px;
	font-family: $baloo;
	background: $backgroundGrey;
	margin-bottom: 20px;
	transition: 0.3s;
	svg {
		margin-right: 5px;
	}
	&:hover,
	&:focus {
		margin-top: -5px;
		margin-bottom: 25px;
		box-shadow: 0 5px $brandYellow;
		cursor: pointer;
	}
	&--loading {
		@extend .button;
		cursor: not-allowed;
		&:hover {
			cursor: not-allowed !important;
		}
	}
	&:disabled {
		background: $backgroundGrey;
		color: $darkGrey;
		&:hover,
		&:focus {
			margin-top: 0;
			margin-bottom: 20px;
			box-shadow: none;
			cursor: not-allowed !important;
		}
	}
}

.blueButton {
	@extend .button;
	background: $brandNavy;
	color: white;
	&--disabled {
		@extend .button;
		background: $backgroundGrey;
		color: $darkGrey;
		&:hover,
		&:focus {
			margin-top: 0;
			margin-bottom: 20px;
			box-shadow: none;
			cursor: not-allowed !important;
		}
	}
}

.redButton {
	@extend .button;
	background-color: $error;
	color: white;
	&--disabled {
		@extend .button;
		background: $backgroundGrey;
		color: $darkGrey;
		&:hover,
		&:focus {
			margin-top: 0;
			margin-bottom: 20px;
			box-shadow: none;
			cursor: not-allowed !important;
		}
	}
}

.greenButton {
	@extend .blueButton;
	background-color: $success;
}

.secondaryButton {
	background-color: $backgroundGrey;
	color: $darkGrey;
	border-radius: 5px;
	padding: 5px 15px;
	font-weight: 800;
	font-size: 16px;
	font-family: $baloo;
	background: $backgroundGrey;
	margin-bottom: 20px;
	transition: 0.3s;
	@include box-shadow-hover-transition();
	&:hover {
		color: $brandNavy;
	}
	&:disabled {
		background: $backgroundGrey;
		color: $darkGrey;
		&:hover,
		&:focus {
			margin-top: 0;
			margin-bottom: 20px;
			box-shadow: none;
			cursor: not-allowed !important;
		}
	}
}

.btn-small {
	display: flex;
	align-items: center;
	border-radius: 0.25rem;
	padding: 0.25rem 0.5rem;
	font-weight: 600;
	font-family: $baloo;
	background-color: $bg-grey;
	color: $dark-grey;
	&:hover {
		cursor: pointer;
		color: $brand-navy;
	}
	svg {
		margin-right: 0.5rem;
	}
	&:disabled {
		cursor: not-allowed;
		color: $shadow-grey;
	}
}

.btn-blue {
	background-color: $brand-navy;
	color: #fff;
	&:hover {
		color: $shadow-grey;
	}
}

.onboarding-btn {
	@include basic-flex();
	@include box-shadow();
	border-radius: 5px;
	color: $brandNavy;
	font-weight: 800;
	font-size: 2rem;
	padding: 0px 15px;
	background: $backgroundWhite;
	font-family: $baloo;

	margin-top: 40px;
	svg {
		margin-right: 5px;
	}
	&:hover,
	&:focus,
	&:active {
		margin-bottom: 5px;
		margin-top: 35px;
		box-shadow: 0 3px 6px rgba($color: #000000, $alpha: 0.18), 0 5px $brandYellow;
	}
	&:disabled {
		@extend .onboarding-btn;
		background: $shadowGrey;
		color: $darkGrey;
		&:hover,
		&:focus,
		&:active {
			cursor: not-allowed;
			margin-bottom: 0px;
			margin-top: 40px;
			box-shadow: 0 3px 6px rgba($color: #000000, $alpha: 0.18);
		}
	}
}
