@keyframes fade-in {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.fade-in {
	animation-name: fade-in;
	animation-duration: 1s;
}
