@import '../modules/colors', '../modules/fonts', '../modules/shadows', '../modules/containers',
	'../modules/breakpoints';

.analytics {
	margin: 25px 50px;
	@include basic-flex(column);
	align-items: center;
	padding-bottom: 50px;
	h3 {
		color: $brandNavy;
	}
	span {
		color: $darkGrey;
		font-weight: 500;
	}
	.tableInfo {
		@include basic-flex();
		align-items: center;
		justify-content: space-between;
		width: 100%;
		margin-bottom: 10px;
		h4 {
			margin: 0;
		}
		span {
			color: $darkGrey;
		}
		select {
			background-color: $backgroundGrey;
			color: $darkGrey;
			font-weight: 600;
			outline: none;
			padding: 5px;
			border-radius: 5px;
			border: none;
			transition: 0.3s;
			font-family: $baloo;
			&:hover,
			&:focus {
				@include box-shadow();
			}
		}
	}
	&__content {
		@include basic-flex(column);
		align-items: center;
		width: 95%;
		max-width: 1000px;
	}
}
