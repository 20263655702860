@import '../modules/colors', '../modules/fonts', '../modules/shadows', '../modules/containers',
	'../modules/breakpoints';

.inner {
	@include modal-inner();

	select {
		@include box-shadow();
		padding: 5px 10px;
		border-radius: 5px;
		color: $brandNavy;
		font-family: $baloo;
		font-weight: 600;
		margin: 0 0 25px;
		outline: none;
		border: none;
		width: max-content;
		max-width: 300px;
		text-align: center;
	}
}

.product {
	@include basic-flex();
}

.image {
	@include profile-image(100px);
}

.info {
	@include basic-flex(column);
	align-items: flex-start;
	height: 100px;
	color: $brandNavy;
	h2 {
		margin: 0;
	}
	span {
		font-weight: 500;
	}
	h3 {
		margin: 0;
		color: $darkGrey;
	}
}

.skus {
	@include basic-flex(column);
	margin-bottom: 15px;
}

.sku {
	@include basic-flex();
	@include box-shadow();
	align-items: center;
	width: 100%;
	justify-content: space-between;
	padding: 5px 15px;
	border-radius: 5px;
	margin-bottom: 5px;
	background-color: $backgroundGrey;
}

.skuNumber {
	margin-right: 5px;
	font-weight: 500;
}

.actionBtn {
	@include center-child();
	@include box-shadow-hover-transition();
	border-radius: 5px;
	color: $darkGrey;
	margin: 0 5px;
	&:hover {
		color: $brandNavy;
		cursor: pointer;
	}
}
