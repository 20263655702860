@import '../modules/colors', '../modules/fonts', '../modules/shadows', '../modules/containers',
	'../modules/breakpoints';

.orderMap {
	@include box-shadow();
	margin-top: 15px;
	width: 100%;
	max-width: 600px;
	height: 200px;
	border-radius: 10px;
	overflow: hidden;
	background-color: $backgroundGrey;
	h1 {
		position: absolute;
		font-size: 18px;
		color: #fff;
		z-index: 2;
		text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
		margin: 3px 5px;
	}
	&--loading {
		@extend .orderMap;
		@include center-child();
	}
}

// location map is found in the edit location modal
.locationMap {
	@extend .orderMap;
	width: 150px;
	align-self: stretch;
	border-radius: 15px;
	background-color: $backgroundGrey;
	margin-right: 15px;
}

.breweryMap {
	@extend .orderMap;
	width: 80%;
	max-width: 500px;
	@media screen and (max-width: $maxPhone) {
		width: 100%;
	}
	&--loading {
		@extend .breweryMap;
		@include center-child();
	}
}

.infoWindow {
	display: flex;
	font-family: $baloo;
	color: $brandNavy;
	&__image {
		@include profile-image(50px);
		margin: 0;
		margin-right: 10px;
		border-radius: 5px;
	}
	h2,
	p {
		margin: 0;
	}
}

.getDirectionsButton {
	@include basic-flex();
	transition: 0.3s;
	margin-top: 10px;
	&:hover {
		cursor: pointer;
		color: $brandNavy;
	}
}
