@import '../modules/colors', '../modules/fonts', '../modules/shadows', '../modules/containers',
	'../modules/breakpoints';

.orders {
	@include basic-flex(column);
	padding: 50px 0;
}

.orderList {
	@include basic-flex(column);
	width: 50%;
	max-width: 800px;
	margin: 25px 0;
	@media screen and (max-width: $maxLandscape) {
		width: 70%;
	}
	@media screen and (max-width: $maxPhone) {
		width: 90%;
		font-size: 0.8rem;
	}
	&__order {
		@include basic-flex();
		@include box-shadow-hover-transition();
		background-color: $backgroundGrey;
		width: 100%;
		margin-bottom: 15px;
		border-radius: 10px;
		font-weight: 600;
		color: $brandNavy;
		@media screen and (max-width: $maxPhone) {
			font-size: 0.75rem;
		}
		&--expanded {
			@extend .orderList__order;
			flex-direction: column;
		}
		&__image {
			@include profile-image(50px);
			margin: 5px;
			margin-right: 10px;
			border-radius: 10px;
			@media screen and (max-width: $maxPhone) {
				@include profile-image(40px);
				margin: 5px;
				margin-right: 10px;
				border-radius: 10px;
			}
		}
		&__nameDate {
			@include basic-flex(column);
			flex-grow: 1;
			span {
				margin-left: 0;
				text-align: left;
				width: 100%;
			}
		}
		&__date {
			margin-left: auto;
			color: $darkGrey;
		}
		&__status {
			@include basic-flex();
			justify-content: flex-start;
			width: 150px;
			margin: 0 15px;
			@media screen and (max-width: $maxPhone) {
				width: 110px;
				margin: 0 6px;
				font-size: 0.66rem;
			}
			&Bubble {
				margin-right: 5px;
			}
		}
		&:hover {
			cursor: pointer;
		}
		&__productAndNote {
			@include basic-flex();
			@media screen and (max-width: $maxPhone) {
				@include basic-flex(column);
				font-size: 0.75rem !important;
			}
			align-items: flex-start;
			justify-content: space-between;
			padding: 10px 25px;
			width: 90%;
			&__note {
				color: $darkGrey;
				margin-left: 10px;
				margin-bottom: 15px;
				width: 45%;
			}
		}
		&__products {
			&__product {
				@include basic-flex();
				align-items: flex-start;
				margin-bottom: 10px;
				&__image {
					@include profile-image(40px);
					margin: 5px 15px 0 0;
					border-radius: 10px;
				}
			}
		}
	}
}

.statusBubble {
	border-radius: 100%;
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
	background-color: $darkGrey;
	width: 15px;
	height: 15px;
	display: inline-block;
	&--red,
	&--cancelled {
		@extend .statusBubble;
		background-color: $error;
	}
	&--pending,
	&--grey {
		@extend .statusBubble;
		background-color: $darkGrey;
	}
	&--inProgress,
	&--yellow {
		@extend .statusBubble;
		background-color: $brandYellow;
	}
	&--success,
	&--green {
		@extend .statusBubble;
		background-color: $success;
	}
	&--closed,
	&--blue {
		@extend .statusBubble;
		background-color: $brandNavy;
	}
}
