@import '../modules/colors', '../modules/fonts', '../modules/shadows', '../modules/containers',
	'../modules/breakpoints';

.order {
	@include basic-flex(column);
	align-items: flex-start;
	padding: 30px 150px 150px;
	@media screen and (min-width: $extraLargeScreen) {
		padding: 30px 20% 150px;
	}
	@media screen and (max-width: $maxLandscape) {
		padding: 30px 100px 150px;
	}
	@media screen and (max-width: $maxPhone) {
		padding: 30px 25px 125px;
	}
	color: $brandNavy;
	&__top {
		@include basic-flex();
		&__image {
			@include profile-image(80px);
			border-radius: 10px;
			margin-left: 0;
		}
		&__info {
			@media screen and (max-width: $maxPhone) {
				h2 {
					font-size: 1rem;
				}
				h3 {
					font-size: 1rem;
				}
			}
			h2 {
				margin: 0 0 5px;
			}
			h3 {
				margin: 0;
			}
			span {
				font-weight: 500;
				color: $darkGrey;
			}
		}
	}
	&__info {
		@include basic-flex(column);
		align-items: flex-start;
		font-weight: 600;
		color: $darkGrey;
		h3 {
			color: $brandNavy;
		}
		span {
			button {
				background-color: $brandNavy;
				color: $backgroundWhite;
				font-family: $baloo;
				font-weight: 500;
				border-radius: 3px;
				margin-left: 20px;
				&:hover {
					color: $brandYellow;
				}
			}
		}
		&__status {
			@include box-shadow();
			padding: 5px;
			border-radius: 5px;
			color: $backgroundWhite;
			&--green {
				@extend .order__info__status;
				background-color: $success;
			}
			&--red {
				@extend .order__info__status;
				background-color: $error;
			}
			&--yellow {
				@extend .order__info__status;
				background-color: $brandYellow;
			}
			&--blue {
				@extend .order__info__status;
				background-color: $brandNavy;
			}
			&--grey {
				@extend .order__info__status;
				background-color: $darkGrey;
			}
		}
	}
	&__conversation {
		max-width: 600px;
		&__message {
			@include box-shadow();
			@include basic-flex(column);
			align-items: flex-start;
			background-color: $backgroundGrey;
			padding: 10px;
			border-radius: 10px;
			min-width: 200px;
			margin-bottom: 20px;
			max-width: 500px;
			&__iconTime {
				@include basic-flex();
				align-items: center;
			}
			&__text {
				color: $brandNavy;
			}
			&__time {
				font-weight: 400;
			}
			&__icon {
				border-radius: 100%;
				height: 20px;
				width: 20px;
				background-size: cover;
				margin-right: 10px;
			}
			&--blue {
				@extend .order__conversation__message;
				background-color: $brandNavy;
				color: #fff !important;
				width: max-content;
				max-width: 500px;
				.order__conversation__message__text {
					color: #fff !important;
				}
			}
		}
		&__action {
			@include basic-flex();
			align-items: center;
			font-weight: 400;
			font-size: 0.9rem;
			padding: 0 15px;
			margin: 10px 0;
			&__info {
				@include basic-flex(column);
				align-items: flex-start;
				margin-left: 15px;
			}
		}
	}
	&__products {
		&__product {
			@include basic-flex();
			margin-bottom: 20px;
			&__image {
				@include profile-image(50px);
				background-color: $shadowGrey;
				margin: 0;
				border-radius: 5px;
			}
			&__info {
				margin: 0 20px;
				flex-grow: 1;
				min-height: 50px;
				h4,
				p {
					margin: 0;
					color: #000;
				}
				p {
					line-height: 0.9rem;
				}
			}
			&__price {
				align-self: flex-start;
				justify-self: flex-end;
				font-weight: 600;
			}
		}
	}
	&__actions {
		@include basic-flex();
		flex-wrap: wrap;
		button,
		a {
			margin-right: 10px;
		}
	}
	&__invoice {
		@include basic-flex();
		&__item {
			@include basic-flex();
			width: 100%;
			span {
				color: $darkGrey;
				font-weight: 600;
			}
			&__label {
				margin-right: 15px;
				width: 100px;
			}
			&__prefix {
				flex-grow: 1;
			}
			&__amount {
				font-weight: 800;
				width: 30px;
			}
		}
	}
	&__applyDiscount {
		margin-top: 25px;
		&__select {
			@include box-shadow-hover-transition();
			font-family: $baloo;
			font-weight: 600;
			color: $brandNavy;
			background-color: #fff;
			border-radius: 5px;
			padding: 5px;
			margin-right: 5px;
			min-width: 50px;
			&:hover {
				color: #fff;
				background-color: $brandNavy;
			}
			&--selected {
				@extend .order__applyDiscount__select;
				color: #fff;
				background-color: $brandNavy;
			}
		}
		&__customAmount {
			margin-top: 20px;
			font-weight: 600;
			input {
				@include box-shadow-hover-transition();
				background-color: $backgroundGrey;
				border-radius: 5px;
				margin-bottom: 20px;
				padding: 5px;
				max-width: 80px;
			}
		}
		.blueButton {
			margin-right: 15px;
		}
		span {
			color: $darkGrey;
		}
	}
	&__applyDiscountToggle {
		color: $darkGrey;
		transition: 0.3s;
		&:hover {
			color: $brandNavy;
			cursor: pointer;
		}
	}

	&__rewardInfo {
		@include basic-flex();
		color: $darkGrey;
		margin-top: 15px;
		svg {
			margin-right: 15px;
		}
	}
}

.kegDeposit {
	margin-top: 15px;
	&__kegInput {
		@include basic-flex();
		margin-bottom: 15px;
		color: $darkGrey;
		font-weight: 600;
		input {
			@include box-shadow-hover-transition();
			padding: 5px;
			border-radius: 5px;
			width: 40px;
			background-color: $backgroundGrey;
			margin-left: 10px;
		}
		&--withHover {
			@extend .kegDeposit__kegInput;
			transition: 0.3s;
			&:hover {
				color: $brandNavy;
				cursor: pointer;
			}
		}
	}
}
