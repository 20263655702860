@import '../modules/colors', '../modules/shadows', '../modules/fonts', '../modules/containers',
	'../modules/breakpoints';

.snackbar {
	div {
		@include header-text(1rem);
		background-color: $brandNavy;
		color: white;
	}

	&--success {
		div {
			@include header-text(1rem);
			background-color: $success;
			color: white;
		}
	}

	&--error {
		div {
			@include header-text(1rem);
			background-color: $error;
			color: white;
		}
	}
}
