@import '../modules/colors', '../modules/fonts', '../modules/shadows', '../modules/containers',
	'../modules/breakpoints';

.bannerAlert {
	@include basic-flex();
	@include box-shadow-hover-transition();
	border-radius: 10px;
	padding: 10px;
	background-color: $brandNavy;
	width: calc(80% - 20px);
	@media screen and (max-width: $maxPhone) {
		width: 90%;
		font-size: 0.8rem;
	}
	max-width: 780px; // 800px - padding (20px)
	margin-bottom: 20px;
	svg {
		margin-right: 20px;
	}
	strong {
		display: block;
		text-shadow: 0 2px 2px rgba($color: #000000, $alpha: 0.2);
	}
	&:hover {
		cursor: pointer;
	}
	&--info {
		@extend .bannerAlert;
		background-color: $brandNavy;
		color: $backgroundGrey;
		background-image: linear-gradient(to bottom right, $brandNavy, $brandYellow 200%);
	}
	&--warning {
		@extend .bannerAlert;
		background-color: $brandYellow;
		color: $brandNavy;
		background-image: linear-gradient(to bottom right, $brandYellow, $backgroundGrey 300%);
	}
	&--danger {
		@extend .bannerAlert;
		background-color: $error;
		color: $backgroundGrey;
		background-image: linear-gradient(to bottom right, $error, $backgroundGrey 300%);
	}
}

.notifications {
	@include basic-flex(column);
	padding: 50px 150px;
	@media screen and (max-width: $maxTablet) {
		padding: 50px 80px;
	}
	@media screen and (max-width: $maxPhone) {
		padding: 50px 30px;
	}
}

.notification {
	width: 100%;
	max-width: 800px;
	border: 1px solid $backgroundGrey;
	background-color: $brandNavy;
	color: $backgroundWhite;
	transition: 0.3s;
	font-size: 0.8rem;
	@media screen and (max-width: $maxTablet) {
		font-size: 0.7rem;
	}
	&:hover {
		cursor: pointer;
		h3 {
			color: $brandYellow;
		}
	}
	h3 {
		margin: 0;
		margin-right: 10px;
	}
	h3,
	span,
	p,
	a {
		margin-left: 15px;
	}
	p {
		font-weight: 500;
	}
	a {
		width: 100px;
	}
	&--read {
		@extend .notification;
		&:hover {
			cursor: pointer;
			h3 {
				color: $darkGrey;
			}
		}
		background-color: $backgroundGrey;
		color: $darkGrey;
	}
	&--read--accountHub {
		@extend .notification;
		&:hover {
			cursor: pointer;
			h3 {
				color: $brandNavy !important;
			}
		}
		background-color: $backgroundWhite;
		color: $darkGrey;
		h3 {
			color: $darkGrey !important;
		}
	}
}
