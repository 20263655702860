@mixin modal-inner($width: 50%, $maxWidth: 800px, $height: max-content) {
	@include box-shadow();
	@include basic-flex(column);
	align-items: center;
	justify-content: space-between;
	width: $width;
	max-width: $maxWidth;
	height: $height;
	padding: 10px;
	outline: none;
	background: white;
	border-radius: 15px;
	overflow: scroll;
	z-index: 10;
	@media screen and (max-width: $maxTablet) {
		width: 70%;
	}
	@media screen and (max-width: $maxPhone) {
		width: 90%;
	}
	input {
		@include body-text();
		@include box-shadow();
		height: 2rem;
		padding: 5px 15px;
		background: $shadowGrey;
		border-radius: 15px;
		width: 80%;
		max-width: 300px;
	}
}

.modal {
	@include center-child();
	&__inner {
		@include modal-inner();
		color: $brandNavy;
		max-height: 90vh;
	}
}

.modalSelector {
	@include box-shadow-hover-transition();
	border-radius: 5px;
	padding: 5px;
	font-family: $baloo;
	font-weight: 600;
	border: none;
	outline: none;
}

.editProfileInfoModal {
	@include center-child();
	&__inner {
		@include box-shadow();
		@include basic-flex(column);
		align-items: center;
		justify-content: space-between;
		width: 60%;
		max-width: 800px;
		max-height: 96vh;
		height: max-content;
		padding: 10px;
		outline: none;
		background: white;
		border-radius: 15px;
		@media screen and (max-width: $maxTablet) {
			width: 70%;
		}
		@media screen and (max-width: $maxPhone) {
			width: 90%;
		}
		h3 {
			color: $brandNavy;
			margin: 0;
			margin-top: 10px;
		}
		&__profileImage {
			@include profile-image(200px);
			background-color: $backgroundGrey;
			&__overlay {
				@include profile-image-overlay($noPhoto: false);
				&--noPhoto {
					@include profile-image-overlay($noPhoto: true);
				}
			}
			&--noPhoto {
				@extend .editProfileInfoModal__inner__profileImage;
			}
		}
		span {
			color: $brandNavy;
			margin: 0 50px 25px;
		}
		input {
			@include body-text();
			@include box-shadow();
			height: 2rem;
			padding: 5px 15px;
			background: $shadowGrey;
			border-radius: 15px;
			width: 80%;
			max-width: 300px;
		}
		textarea {
			width: 80%;
			max-width: 400px;
			margin-bottom: 30px;
		}
		&__locationData {
			display: flex;
			align-items: center;
			margin-bottom: 25px;
			&__map {
				@include box-shadow();
				width: 150px;
				align-self: stretch;
				border-radius: 15px;
				background-color: $backgroundGrey;
				margin: 0 25px 0 15px;
			}
			&__inputs {
				display: flex;
				flex-direction: column;
				.regionListInput {
					width: 50px;
					margin-right: 10px;
				}
				.postalCodeInput {
					width: 100px;
				}
			}
		}
	}
}

.addToCartModal {
	@include center-child();
	&__inner {
		@include modal-inner();
		&__product {
			@include basic-flex();
			&__image {
				@include profile-image(100px);
			}
			&__info {
				@include basic-flex(column);
				align-items: flex-start;
				height: 100px;
				color: $brandNavy;
				h2 {
					margin: 0;
				}
				span {
					font-weight: 500;
				}
				h3 {
					margin: 0;
					color: $darkGrey;
				}
			}
		}
		&__quantities {
			@include basic-flex(column);
			margin-bottom: 15px;
			&__row {
				@include basic-flex();
				@include box-shadow();
				align-items: center;
				width: 100%;
				justify-content: space-between;
				padding: 5px 15px;
				border-radius: 5px;
				margin-bottom: 5px;
				background-color: $backgroundGrey;
				&__number {
					margin-right: 5px;
					font-weight: 500;
				}
				&__actionButton {
					@include center-child();
					@include box-shadow-hover-transition();
					border-radius: 5px;
					color: $darkGrey;
					margin: 0 5px;
					&:hover {
						color: $brandNavy;
						cursor: pointer;
					}
				}
			}
		}
		select {
			@include box-shadow();
			padding: 5px 10px;
			border-radius: 5px;
			color: $brandNavy;
			font-family: $baloo;
			font-weight: 600;
			margin: 0 0 25px;
			outline: none;
			border: none;
		}
	}
}

.scheduleDeliveryModal {
	&__content {
		@include basic-flex(column);
		font-weight: 500;
		&__changeDate {
			@include box-shadow-hover-transition();
			border-radius: 5px;
			background-color: $brandNavy;
			color: white;
			padding: 5px;
			margin-bottom: 10px;
			&:hover {
				color: $brandYellow;
				cursor: pointer;
			}
		}
		select {
			@include box-shadow-hover-transition();
			padding: 5px;
			color: $brandNavy;
			border-radius: 5px;
			margin-bottom: 40px;
			border: solid 2px $brandNavy;
		}
	}
}
