@import './colors.scss';

@mixin box-shadow() {
	box-shadow: 0 3px 6px rgba($color: #000000, $alpha: 0.18);
}

@mixin box-shadow-hover-transition() {
	box-shadow: 0 3px 6px rgba($color: #000000, $alpha: 0.18);
	transition: 0.4s;
	&:hover {
		box-shadow: 0 5px 7px rgba($color: #000000, $alpha: 0.18);
	}
}

@mixin yellow-box-shadow($height: 3px) {
	box-shadow: 0 $height $brandYellow;
}

@mixin yellow-text-shadow($height: 3px) {
	text-shadow: 0 $height $brandYellow;
}
